import React from "react"
import { Box, Text, VStack } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import { useCore } from "@app/hooks/useCore"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"
import Link from "@app/components/Link"

type Props = GatsbyTypes.SanitySectionTextButton & AnalyticProps

const TextButton: React.FC<Props> = ({ content: rawContent, link, button, innerRef, handleTrackingClick, tag }) => {
  const { urlResolver } = useRoutes()
  const {
    helpers: { sanityContent },
  } = useCore()
  const content = sanityContent(rawContent)
  const customButtonLink = urlResolver(button)
  const customLink = urlResolver(link)

  return content ? (
    <Box as="section" ref={innerRef} py={{ base: "8", lg: "18" }} px={{ base: 4, lg: "20" }} bg="background.white" textAlign="center">
      <Text
        as={tag}
        size="largeParagraph"
        maxW="180"
        mx="auto"
        mb={{ base: "4", lg: "5" }}
        sx={{ p: { mb: { base: "4", lg: "5" } }, "p:last-child": { mb: "0" } }}
      >
        {content}
      </Text>

      <VStack gap={{ base: "3", lg: "4" }}>
        {customButtonLink ? (
          <ButtonWithIcon
            as={Link}
            href={customButtonLink.url}
            isExternal={customButtonLink.external}
            iconName="arrows/chevron-right"
            variant="solidSecondary"
            onClick={handleTrackingClick}
            w={{ base: "full", lg: "auto" }}
            maxW={{ base: "container.sm", lg: "none" }}
          >
            {customButtonLink.title}
          </ButtonWithIcon>
        ) : null}

        {customLink ? (
          <Text size="textLinkSmall" as={Link} to={customLink.url} isExternal={customLink.external} onClick={handleTrackingClick}>
            {customLink.title}
          </Text>
        ) : null}
      </VStack>
    </Box>
  ) : null
}

export default React.memo(withSection(TextButton))
